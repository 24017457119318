import { useEffect, useState } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from 'Auth';
import {  ThemeProvider,CssBaseline } from '@mui/material'; 
import { ColorModeContext, useMode } from "./theme";
import 'react-notifications/lib/notifications.css'; 
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from 'layout-new/Layout';
import Dashboard from 'views/dashboard'; 

// project imports 
import Login from 'views/login/Login';
import NotFound from 'views/login/404'
import ChangePassword from 'views/login/ChangePassword'; 
 

import BillTaxReport from 'views/reports/BilltaxReport'; 

import BlogList from 'views/transaction/Blog'; 
import BlogMaster from 'views/transaction/Blog/AddNewItemForm';   

import PageList from 'views/transaction/Page'; 
import PageMaster from 'views/transaction/Page/AddNewItemForm';   

import Settings from 'views/Settings';
import Downloads from 'views/Downloads';

import Protected from 'ProtectedRoute';
import { NotificationContainer } from 'react-notifications'; 

 
// ==============================|| APP ||============================== //


const queryClient = new QueryClient();
const App = () => { 
    const [theme, colorMode] = useMode();
    const [isLoggedIn, setIsLoggedIn] = useState(null);
   

    useEffect(() => {
        if (isLoggedIn == null) {
            if (localStorage.getItem('access_token') == null) {
                setIsLoggedIn(false)
            } else {
                setIsLoggedIn(true)
            }
        }
    });

    return (
        <ColorModeContext.Provider value={colorMode}>
        <QueryClientProvider client={queryClient}>   
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <NotificationContainer />
                
                    <AuthProvider>                        
                        <Routes>
                            <Route path='/login' element={localStorage.getItem('access_token') == null ? <Login /> : <Navigate to="/" replace />} />
                            <Route path='/' element={<Protected isLoggedIn={localStorage.getItem('access_token') != null}><Layout /></Protected>}>
                                <Route index element={<Dashboard />} /> 

                                <Route path='app/change-password' element={<ChangePassword />} /> 

                                <Route path='app/blog' element={<BlogMaster />} /> 
                                <Route path='app/blog-list' element={<BlogList />} />   

                                <Route path='app/page' element={<PageMaster />} /> 
                                <Route path='app/page-list' element={<PageList />} />   
 

                                <Route path='app/settings' element={<Settings />} />
                                <Route path='app/Downloads' element={<Downloads />} />

                                <Route path='app/bill-tax-report' element={<BillTaxReport />} /> 

                            </Route>
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </AuthProvider>
                    
                </ThemeProvider> 
            <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </QueryClientProvider>
        </ColorModeContext.Provider>
    );
};

export default App;

