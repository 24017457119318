import { useState, useEffect, useRef } from 'react'; 
import { Box, TableContainer, useTheme, MenuItem, TextField, Stack, Button,Paper } from '@mui/material';
import { Link } from 'react-router-dom';

import '@trendmicro/react-paginations/dist/react-paginations.css';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import TableFooter from '@mui/material/TableFooter';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import DeleteConfirmationDialog from '../../../component/DeleteConfirmationDialog/DeleteConfirmationDialog';
import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme'; 
import moment from 'moment';
const ItemMaster = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const deleteConfirmationDialog = useRef(null);
 
    const [searchBy, setSearchBy] = useState({
        searchText : '',
        status : "Active",
        websitekey : localStorage.getItem('userName')
    }); 

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (items == null) {
            loadItems();
        }
    }, []);

    useEffect(() => {
        (async () => {
            loadItems(page);
        })();
    }, [page, rowsPerPage]);

    const loadItems = async () => {
        (async () => {
            console.log("loading data...");
            setLoading(true);
            await api
                .get('api/website/blog?PageNumber=' + (page + 1) + '&PageSize=' + rowsPerPage, {
                    params: searchBy
                })
                .then((response) => {
                    console.log(response.data.data);
                    setCount(response.data.totalRecords);
                    setItems(response.data.data);
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                })
                .finally(() => {
                    setLoading(false);
                });
        })();
    };

    const onReload = (e) => {
        e.preventDefault();
        loadItems();
    };

    const onDelete = (item) => {
        deleteConfirmationDialog.current.open();
        setSelectedItem(items[items.indexOf(item)]);
    };

    const deleteItemPermanently = async () => {
        deleteConfirmationDialog.current.close();
        setLoading(true);
        await api
            .delete('api/website/blog?id=' + selectedItem.id)
            .then(async (response) => { 
                NotificationManager.success("Deleted Successfully.");
                 await  loadItems(); 
            })
            .catch((error) => { 
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChangePage = async (newPage) => {
        //setPage(page + 1);
        console.log('page........');
        console.log('New Page : ' + newPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        console.log('Rows per page changed !');
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function TablePaginationActions() {
        const handleFirstPageButtonClick = () => {
            setPage(0);
            // loadData(1);
        };

        const handleBackButtonClick = () => {
            setPage(page - 1);
            //loadData(page - 1);
        };

        const handleNextButtonClick = () => {
            setPage(page + 1);
            //loadData(page + 1);
        };

        const handleLastPageButtonClick = () => {
            setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
            //loadData(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                    {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
                    <FirstPageIcon />
                </IconButton>
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                    {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
                    <LastPageIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <Box m="20px">
             <Header title="BLOG MANAGEMENT" subtitle="manage blogs" />
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">
                <Stack direction={'row'} spacing={2} justifyContent="flex-end" gridColumn="span 12"> 
                    <Link to="/app/blog" state={{ id: 0 }}>
                        Add New Blog
                    </Link>
                </Stack> 
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    p={2}
                >
                    <TextField
                        label="Search Text"
                        fullWidth
                        value={searchBy.searchText}
                        onChange={(e) => setSearchBy({...searchBy , searchText : e.target.value})}
                        variant="standard"
                    />
                    <TextField
                        label="Select Status"
                        value={searchBy.status}
                        onChange={(e) => setSearchBy({...searchBy , status : e.target.value})}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem selected key={0} value="Active">
                            Active
                        </MenuItem>
                        <MenuItem key={1} value="InActive">
                            InActive
                        </MenuItem>
                    </TextField>
                    <Button disabled={loading} onClick={(e) => onReload(e)} color="secondary">
                        Search
                    </Button>
                </Stack>

                <Box
                    gridColumn="span 12"
                    backgroundColor={colors.primary[400]}
                    sx={{
                        '& .MuiTableHead-root': {
                            backgroundColor: colors.greenAccent[700]
                        },
                        '& .MuiTableRow-footer': {
                            backgroundColor: colors.redAccent[700]
                        }
                    }}
                >
                    <TableContainer component={Paper}>
                        {items && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Slug</TableCell>
                                        <TableCell>Initial Content</TableCell> 
                                        <TableCell>Status</TableCell>
                                        <TableCell>Actions</TableCell>  
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items &&
                                        items.map((item, key) => 
                                                <TableRow key={key}>
                                                    <TableCell>{item.category}</TableCell>
                                                    <TableCell>{item.title}</TableCell>
                                                    <TableCell>{item.slug}</TableCell>
                                                    <TableCell>{item.intialContent}</TableCell>  
                                                    <TableCell>
                                                        <span
                                                            className={
                                                                item.status == 'InActive' ? 'badge badge-danger' : 'badge badge-primary'
                                                            }
                                                        >
                                                            {item.status}
                                                        </span>
                                                    </TableCell>  
                                                    <TableCell>
                                                        <Link to="/app/blog" state={{ id: item.id }}>
                                                            <IconButton aria-label="Edit" size="small">
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Link>
                                                        <IconButton
                                                            onClick={() => onDelete(item)}
                                                            color="error"
                                                            aria-label="Delete"
                                                            size="small"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>  
                                                </TableRow> 
                                        )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                            labelRowsPerPage={<span>Rows:</span>}
                                            labelDisplayedRows={({ page }) => {
                                                return `Page: ${page + 1}`;
                                            }}
                                            backIconButtonProps={{
                                                color: 'secondary'
                                            }}
                                            nextIconButtonProps={{ color: 'secondary' }}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'page number'
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                </Box>

                <DeleteConfirmationDialog
                    ref={deleteConfirmationDialog}
                    title="Are You Sure Want To Delete?"
                    message="This will delete item permanently."
                    onConfirm={() => deleteItemPermanently()}
                />
            </Box>
        </Box>
    );
};
export default ItemMaster;
