import { useState, useEffect } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ReportOffOutlined from '@mui/icons-material/ReportOffOutlined';
import ReportOutlined from '@mui/icons-material/ReportOutlined';
import ReportGmailerrorredOutlined from '@mui/icons-material/ReportGmailerrorredOutlined';
import logo from 'assets/img/site-logo.png';
import moment from 'moment';
import api from 'api';
import {
    CancelOutlined,
    CardMembershipOutlined,
    CategoryOutlined,
    ChairAltOutlined,
    DataArrayOutlined,
    InstallMobileOutlined,
    OnlinePredictionOutlined,
    PendingActionsOutlined,
    PrintOutlined,
    ProductionQuantityLimitsOutlined,
    QrCodeOutlined,
    ReportOffRounded,
    RoomOutlined,
    SettingsOutlined,
    SmsOutlined,
    SportsHockeyOutlined,
    StackedLineChartOutlined,
    VerifiedUserOutlined
} from '@mui/icons-material';
const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Tooltip title={title}>
            <MenuItem
                active={selected === title}
                style={{
                    color: colors.grey[100]
                }}
                onClick={() => setSelected(title)}
                icon={icon}
            >
                <Typography>{title}</Typography>
                <Link to={to} />
            </MenuItem>
        </Tooltip>
    );
};

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState('Dashboard');
    const [currentDate, setCurrentDate] = useState();
    const [branch, setBranch] = useState(''); 
    return (
        <Box
            sx={{
                '& .pro-sidebar-inner': {
                    background: `${colors.primary[400]} !important`
                },
                '& .pro-icon-wrapper': {
                    backgroundColor: 'transparent !important'
                },
                '& .pro-inner-item': {
                    padding: '5px 35px 5px 20px !important'
                },
                '& .pro-inner-item:hover': {
                    color: '#868dfb !important'
                },
                '& .pro-menu-item.active': {
                    color: '#6870fa !important'
                },
                '& .pro-sidebar-label': {
                    color: `${colors.primary[100]} !important`
                }
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: '10px 0 20px 0',
                            color: colors.grey[100]
                        }}
                    >
                        {!isCollapsed && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                                <Typography variant="h3" color={colors.grey[100]}>
                                    <img src={logo} height={40} width={110} />
                                </Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={`../../assets/user.png`}
                                    style={{ cursor: 'pointer', borderRadius: '50%' }}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography variant="h4" color={colors.grey[100]} fontWeight="bold" sx={{ m: '10px 0 0 0' }}>
                                    {localStorage.getItem('userName')}
                                </Typography>
                                <Typography variant="h5" color={colors.grey[100]} fontWeight="bold" sx={{ m: '10px 0 0 0' }}>
                                  Role :  {localStorage.getItem('role')}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                        <Item title="Dashboard" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />

                        <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                            Pages
                        </Typography>
                        <SubMenu title="Transaction" icon={<ContactsOutlinedIcon />}> 

                           
                        <Item
                                        title="Blog"
                                        to="app/blog-list"
                                        icon={<PersonOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />

<Item
                                        title="Page"
                                        to="app/page-list"
                                        icon={<PersonOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                
 
                        </SubMenu>

                        <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                            Config
                        </Typography>
                        <SubMenu title="Settings" icon={<SettingsOutlined />}>
                          
                                    <Item
                                        title="Account Master"
                                        to="app/account-master-list"
                                        icon={<PersonOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    /> 
                               
                                <Item
                                    title="Settings"
                                    to="app/settings"
                                    icon={<SettingsOutlined />}
                                    selected={selected}
                                    setSelected={setSelected}
                                /> 
                        </SubMenu>

                        <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                            Reports
                        </Typography>

                        <SubMenu title="Reports" icon={<BarChartOutlinedIcon />}> 
                                    <Item
                                        title="Bill Tax Report"
                                        to="/app/bill-tax-report"
                                        icon={<ReportOffOutlined />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />  
                        </SubMenu>
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
};

export default Sidebar;
