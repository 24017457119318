import { useState, useEffect,useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Switch, Button, MenuItem, TextField, Box, FormControlLabel, Typography, Stack, Divider, useTheme } from '@mui/material';

import RctSectionLoader from '../../../component/RctSectionLoader/RctSectionLoader';
import api from 'api';
import Header from '../../../components/Header';
import { NotificationManager } from 'react-notifications';
import { tokens } from '../../../theme';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';


const AddNewItemForm = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation();
    const navigate = useNavigate();
    const id = location.state.id;  
 


    const initialItem = {
        id: 0,
        websiteKey: localStorage.getItem('userName'),         
        slug: '', 
        title: '',
        metaTitle : '',
        metaDescription : '',
        initialContent: '',
        imageName: '',
        category : '',
        blogContent: '', 
        status: 'Active'
        };
    const [addNewItemDetail, setAddNewItemDetail] = useState(initialItem);
    const [loading, setLoading] = useState(false);  

    console.log(addNewItemDetail);
    const addNewItem = async () => {
        if (addNewItemDetail.title === 0) {
            NotificationManager.error('Title Required !');
            return;
        }
        if (addNewItemDetail.intialContent === 0) {
            NotificationManager.error('intialContent Required !');
            return;
        }
        if (addNewItemDetail.blogContent === '') {
            NotificationManager.error('blogContent Required !');
            return;
        }
        if (addNewItemDetail.imageName === '') {
            NotificationManager.error('imageName Required !');
            return;
        }
        if (addNewItemDetail.slug === '') {
            NotificationManager.error('slug Required !');
            return;
        }
        //console.log(JSON.stringify(addNewItemDetail));
        setLoading(true);
        await api
            .post('api/website/blog', JSON.stringify(addNewItemDetail))
            .then((response) => { 
                    NotificationManager.success('Blog Added Successfully.');
                    navigate('/app/blog-list'); 
            })
            .catch((error) => { 
            })
            .finally(() => {
                setLoading(false);
            });
    }; 
    const updateItem = async () => {
        if (addNewItemDetail.slug === '') {
            NotificationManager.error('slug Required !');
            return;
        }
        if (addNewItemDetail.metaTitle === '') {
            NotificationManager.error('metaTitle Required !');
            return;
        }
        if (addNewItemDetail.metaDescription === '') {
            NotificationManager.error('metaDescription Required !');
            return;
        }
        if (addNewItemDetail.title === 0) {
            NotificationManager.error('Blog Title Required !');
            return;
        }
        if (addNewItemDetail.intialContent === 0) {
            NotificationManager.error('intialContent Required !');
            return;
        }
        if (addNewItemDetail.imageName === '') {
            NotificationManager.error('imageName Required !');
            return;
        }
        if (addNewItemDetail.blogContent === '') {
            NotificationManager.error('blogContent Required !');
            return;
        }
       
       
        console.log(JSON.stringify(addNewItemDetail));
        setLoading(true);
        await api
            .put('api/website/blog/' + addNewItemDetail.id, JSON.stringify(addNewItemDetail))
            .then((response) => { 
                    NotificationManager.success('Blog Updated Successfully.');

                    navigate('/app/blog-list'); 
            })
            .catch((error) => { 
            })
            .finally(() => {
                setLoading(false);
            });
    }; 

    useEffect(() => {
        if (addNewItemDetail.id == 0 && id > 0) {
            (async () => {
                await api
                    .get('api/website/blog/' + id)
                    .then((response) => {
                        console.log(response.data);
                        setAddNewItemDetail({
                            id: response.data.id,
                            metaTitle :  response.data.metaTitle,
                            metaDescription :  response.data.metaDescription,
                            title: response.data.title,
                            initialContent: response.data.initialContent,                                                   
                            imageName: response.data.imageName,
                            slug: response.data.slug, 
                            category:response.data.category, 
                            status: response.data.status,
                            blogContent: response.data.blogContent
                        });
                    })
                    .catch((error) => { 
                    });
            })();
        }
    }, [id > 0]);


    const [data, setData] = useState('');
    const isMounted = useRef(false);

    const handleEditorChange = (event, editor) => {
        console.log("c");
        // const data = editor.getData();
        // setAddNewItemDetail({...addNewItemDetail,blogContent:data});

        const data = editor.getData();
    setData(data);
      }; 
 
      useEffect(() => {
        isMounted.current = true;
      }, []);

      useEffect(() => {
        if (isMounted.current) {
            setAddNewItemDetail({...addNewItemDetail,blogContent:data})
        }
      }, [data]);

      console.log(addNewItemDetail);
    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="BLOG" subtitle="add/edit blog" />
            </Box>
            {loading && <RctSectionLoader />}
          
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px">

                <Stack spacing={1} direction="column" gridColumn="span 12" backgroundColor={colors.primary[400]} p={2}>                                       
                
                
                    <TextField
                        fullWidth
                        label="Slug"
                        value={addNewItemDetail.slug}
                        onChange={(e) => setAddNewItemDetail({...addNewItemDetail ,slug : e.target.value})}
                        variant="standard"
                        inputProps={{
                            maxLength: 200,
                        }}    
                    />
                      <TextField
                        fullWidth
                        label="Meta Title"
                        value={addNewItemDetail.metaTitle}
                        onChange={(e) => setAddNewItemDetail({...addNewItemDetail ,metaTitle : e.target.value})}
                        variant="standard"
                        inputProps={{
                            maxLength: 100,
                        }}    
                    />
                      <TextField
                        fullWidth
                        label="Meta Description"
                        value={addNewItemDetail.metaDescription}
                        onChange={(e) => setAddNewItemDetail({...addNewItemDetail ,metaDescription : e.target.value})}
                        variant="standard"
                        inputProps={{
                            maxLength: 200,
                        }}    
                    />
                    <TextField
                        fullWidth
                        label="Blog Title"
                        value={addNewItemDetail.title}
                        onChange={(e) => setAddNewItemDetail({...addNewItemDetail ,title : e.target.value})}
                        variant="standard"
                        inputProps={{
                            maxLength: 100,
                        }}    
                    />
                    <TextField
                        fullWidth
                        label="Initial Content"
                        value={addNewItemDetail.initialContent}
                        onChange={(e) => setAddNewItemDetail({...addNewItemDetail ,initialContent : e.target.value})}
                        variant="standard"
                        inputProps={{
                            maxLength: 200,
                        }}    
                    />
                      <TextField
                        fullWidth
                        label="Category"
                        value={addNewItemDetail.category}
                        onChange={(e) => setAddNewItemDetail({...addNewItemDetail ,category : e.target.value})}
                        variant="standard"
                        inputProps={{
                            maxLength: 30,
                        }}    
                    />                   
                      <TextField
                        fullWidth
                        label="Image Url"
                        value={addNewItemDetail.imageName}
                        onChange={(e) => setAddNewItemDetail({...addNewItemDetail ,imageName : e.target.value})}
                        variant="standard"
                       inputProps={{
                            maxLength: 500,
                        }}     
                    /> 
                    <TextField
                        label="Select Status"
                        value={addNewItemDetail.status}
                        onChange={(e) => setAddNewItemDetail({...addNewItemDetail ,status : e.target.value})}
                        select
                        fullWidth
                        variant="standard"
                    >
                        <MenuItem selected key={0} value="Active">
                            Active
                        </MenuItem>
                        <MenuItem key={1} value="InActive">
                            InActive
                        </MenuItem>
                    </TextField>  
                    <CKEditor 
                        editor={ClassicEditor}
                        data={addNewItemDetail.blogContent}
                        onChange={(e,d)=>handleEditorChange(e,d)}
                    />

                    <br />
                    <br />
 
                    {id == 0 ? (
                        <Button variant="contained" color="secondary" onClick={() => addNewItem()}>
                            Add
                        </Button>
                    ) : (
                        <Button variant="contained" color="secondary" onClick={() => updateItem()}>
                            Update
                        </Button>
                    )}

                    {'  '}
                    <Button variant="contained" color="error" onClick={() => navigate('/app/blog-list')}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};
export default AddNewItemForm;

